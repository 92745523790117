<template>
  <v-text-field
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :rules="[validate]"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="showPassword = !showPassword"
    :type="showPassword ? 'text' : 'password'"
    autocomplete="off"
  ></v-text-field>
</template>

<script>
export default {
  name: "PasswordField",
  props: {
    value: String,
    programGroup: Object,
    passwordMinimumCharacters: { type: Number, required: false },
    passwordMinimumDigits: { type: Number, required: false },
    passwordMinimumAlpha: { type: Number, required: false },
    passwordMinimumSpecial: { type: Number, required: false },
    passwordAllowableSpecialCharacters: { type: String, required: false }, // per OWASP
    passwordPreviousPasswordCheck: { type: Boolean, required: false },
    passwordUsernameCheck: { type: Boolean, required: false }
  },
  data: () => ({
    options: {},
    showPassword: false
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    validate(password) {
      if (password) {
        let regex = "";
        if (this.options.passwordAllowableSpecialCharacters) {
          regex = RegExp("[^" + this.options.passwordAllowableSpecialCharacters + "]", "g");
        }
        if (this.options.passwordMinimumCharacters && password.length < this.options.passwordMinimumCharacters) {
          return "Password must be a minium of " + this.options.passwordMinimumCharacters;
        } else if (
          this.options.passwordMinimumDigits &&
          this.options.passwordMinimumDigits > 0 &&
          password.replace(/[^0-9]/g, "").length < this.options.passwordMinimumDigits
        ) {
          return this.options.passwordMinimumDigits == 1
            ? "Password must contain at least 1 digit"
            : "Password must contain at least " + this.options.passwordMinimumDigits + " digits";
        } else if (
          this.options.passwordMinimumAlpha &&
          this.options.passwordMinimumAlpha > 0 &&
          password.replace(/[^A-Za-z]/g, "").length < this.options.passwordMinimumAlpha
        ) {
          return this.options.passwordMinimumDigits == 1
            ? "Password must contain at least 1 alpha character"
            : "Password must contain at least " + this.options.passwordMinimumAlpha + " alpha characters";
        } else if (
          this.options.passwordMinimumSpecial &&
          this.options.passwordMinimumSpecial > 0 &&
          this.options.passwordAllowableSpecialCharacters &&
          this.options.passwordAllowableSpecialCharacters.length > 0 &&
          password.replace(regex, "").length < this.options.passwordMinimumSpecial
        ) {
          return this.options.passwordMinimumDigits == 1
            ? "Password must contain at least 1 special character"
            : "Password must contain at least " + this.options.passwordMinimumSpecial + " special characters";
        } else {
          return true;
        }
      } else {
        return "Password is required";
      }
    }
  },
  mounted() {
    if (this.programGroup) {
      this.options.passwordMinimumCharacters = this.programGroup.passwordMinimumCharacters;
      this.options.passwordMinimumDigits = this.programGroup.passwordMinimumDigits;
      this.options.passwordMinimumAlpha = this.programGroup.passwordMinimumAlpha;
      this.options.passwordMinimumSpecial = this.programGroup.passwordMinimumSpecial;
      this.options.passwordAllowableSpecialCharacters = this.programGroup.passwordAllowableSpecialCharacters;
      this.options.passwordPreviousPasswordCheck = this.programGroup.passwordPreviousPasswordCheck;
      this.options.passwordUsernameCheck = this.programGroup.passwordUsernameCheck;
    }
    if (this.passwordMinimumCharacters) {
      this.options.passwordMinimumCharacters = this.passwordMinimumCharacters;
    }
    if (this.passwordMinimumDigits) {
      this.options.passwordMinimumDigits = this.passwordMinimumDigits;
    }
    if (this.passwordMinimumAlpha) {
      this.options.passwordMinimumAlpha = this.passwordMinimumAlpha;
    }
    if (this.passwordMinimumSpecial) {
      this.options.passwordMinimumSpecial = this.passwordMinimumSpecial;
    }
    if (this.passwordAllowableSpecialCharacters) {
      this.options.passwordAllowableSpecialCharacters = this.passwordAllowableSpecialCharacters;
    }
    if (this.passwordPreviousPasswordCheck) {
      this.options.passwordPreviousPasswordCheck = this.passwordPreviousPasswordCheck;
    }
    if (this.passwordUsernameCheck) {
      this.options.passwordUsernameCheck = this.passwordUsernameCheck;
    }
  }
};
</script>
