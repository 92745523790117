<template>
  <v-card flat v-bind="$attrs" v-on="$listeners" :loading="loading">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-form ref="organizationForm" @submit.prevent="onSubmit" v-else v-model="validForm">
      <v-container>
        <v-row>
          <v-col class="pb-0">
            <v-alert border="top" type="info">
              <span v-if="canEdit">
                Please use the form below to update your
                {{ $i18n.translate("Organization") }} information.
              </span>
              <span v-else> Below is your {{ $i18n.translate("Organization") }} information on file. </span>
            </v-alert>
            <v-alert dismissible type="success" v-if="profileUpdated">
              {{ $i18n.translate("Organization") }} Updated Successfully
            </v-alert>
            <slot v-if="!isModifiable" name="messageWhenOrganizationIsNotEditable"></slot>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showName">
            <v-text-field
              dense
              name="name"
              :label="$i18n.translate('Name')"
              v-model="organizationForm.name"
              :class="requireName ? 'required' : ''"
              :rules="requireName ? rules.name : []"
              :error-messages="$error.getValidationError(errors, 'name')"
              @input="$error.clearValidationError(errors, 'name')"
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              dense
              name="dba"
              :label="$i18n.translate('Dba')"
              v-model="organizationForm.dba"
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              dense
              name="website"
              :label="$i18n.translate('Website')"
              v-model="organizationForm.website"
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showAddress">
            <v-text-field
              dense
              name="address1"
              :label="$i18n.translate('Address') + ' 1'"
              v-model="organizationForm.address.address1"
              :class="requireAddress ? 'required' : ''"
              :rules="requireAddress ? rules.address1 : []"
              :error-messages="$error.getValidationError(errors, 'address1')"
              @input="$error.clearValidationError(errors, 'address1')"
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showAddress">
            <v-text-field
              dense
              name="address2"
              :label="$i18n.translate('Address') + ' 2'"
              v-model="organizationForm.address.address2"
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showAddress">
            <v-text-field
              dense
              name="city"
              :label="$i18n.translate('City')"
              v-model="organizationForm.address.city"
              :class="requireAddress ? 'required' : ''"
              :rules="requireAddress ? rules.city : []"
              :error-messages="$error.getValidationError(errors, 'city')"
              @input="$error.clearValidationError(errors, 'city')"
              :disabled="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showAddress">
            <CountryField
              dense
              name="country"
              :label="$i18n.translate('Country')"
              v-model="organizationForm.address.country"
              :program="selectedProgram"
              :class="requireAddress ? 'required' : ''"
              :rules="requireAddress ? rules.country : []"
              :error-messages="$error.getValidationError(errors, 'country')"
              @input="$error.clearValidationError(errors, 'country')"
              :disabled="disableCountryField"
            ></CountryField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showAddress">
            <RegionField
              dense
              name="region"
              :country="organizationForm.address.country"
              v-model="organizationForm.address.region"
              :class="requireAddress ? 'required' : ''"
              :rules="requireAddress ? rules.state : []"
              :error-messages="$error.getValidationError(errors, 'region')"
              @input="$error.clearValidationError(errors, 'region')"
              :disabled="!canEdit"
            ></RegionField>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showAddress">
            <PostalCodeField
              dense
              name="postalCode"
              v-model="organizationForm.address.postalCode"
              :country="organizationForm.address.country"
              :class="requireAddress ? 'required' : ''"
              :rules="requireAddress ? rules.postalCode : []"
              :error-messages="$error.getValidationError(errors, 'postalCode')"
              @input="$error.clearValidationError(errors, 'postalCode')"
              :disabled="!canEdit"
            ></PostalCodeField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showPhone">
            <PhoneNumberField
              dense
              name="phoneNumber1"
              :label="$i18n.translate('Phone Number')"
              v-model="organizationForm.phoneNumber1"
              :country="organizationForm.address.country"
              :class="requirePhone ? 'required' : ''"
              :rules="requirePhone ? rules.phoneNumber : undefined"
              :disabled="!canEdit"
            ></PhoneNumberField>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showTIN">
            <FeinField
              dense
              name="tin"
              v-model="organizationForm.tin"
              :country="organizationForm.address.country"
              :rules="requireTin ? rules.ein_tin : []"
              :class="requireTin ? 'required' : ''"
              :disabled="!organizationForm.address.country || !canEdit"
            ></FeinField>
          </v-col>
        </v-row>
        <v-row v-if="$privilege.isImpersonating() && organizationGroup != undefined">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-text-field
              dense
              name="organizationGroup"
              :label="$i18n.translate('Organization Group')"
              v-model="organizationGroup"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn v-if="canEdit" type="submit" color="primary" @click="onSubmit" :disabled="!validForm">Update</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";
import CountryField from "../../fields/CountryField";
import FeinField from "../../fields/FeinField";
import PhoneNumberField from "../../fields/PhoneNumberField";
import PostalCodeField from "../../fields/PostalCodeField";
import RegionField from "../../fields/RegionField";

export default {
  name: "ProfileOrganizationTab",
  props: {
    value: { type: [Boolean, Event], default: false }
  },
  components: {
    CountryField,
    RegionField,
    PhoneNumberField,
    PostalCodeField,
    FeinField
  },
  data: () => ({
    validForm: false,
    loading: true,
    isModifiable: false,
    disableCountryField: true,
    organizationForm: { address: {} },
    organizationGroup: undefined,
    rules: {
      name: [v => !!v || "Name is required"],
      ein_tin: [v => !!v || "EIN/TIN is required"],
      address1: [v => !!v || "Address 1 is required"],
      city: [v => !!v || "City is required"],
      country: [v => !!v || "Country is required"],
      state: [v => !!v || "State/Province/Region is required"],
      postalCode: [v => !!v || "Postal Code is required"],
      phoneNumber: [v => !!v || "Phone Number is required"]
    },
    errors: {},
    profileUpdated: false
  }),
  methods: {
    fetchData() {
      if (!this.selectedParticipant.organization) {
        return;
      }

      this.loading = true;
      return ApiService.get("/api/organizations/" + this.selectedParticipant.organization.id)
        .then(({ data }) => {
          this.organizationForm = data;
          this.isModifiable = !(this.organizationForm.source && this.organizationForm.source.length > 0);
          this.organizationForm.address = this.organizationForm.address ? this.organizationForm.address : {};
          this.disableCountryField = this.organizationForm.address.country == undefined ? false : true;
          if (this.$privilege.isImpersonating()) {
            return ApiService.getRelatedObject("organizationGroups", data).then(({ data }) => {
              let organizationGroups = data._embedded.organizationGroups;
              organizationGroups.forEach(og => {
                if (og.category == "CPG") {
                  this.organizationGroup = og.name + " - " + og.description;
                }
              });
            });
          } else {
            return Promise.resolve();
          }
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            if (this.$refs && this.$refs.organizationForm) {
              this.$refs.organizationForm.validate();
            }
          });
          this.$emit("onLoad");
        });
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};

      let postForm = Object.assign({}, this.organizationForm);
      //Sending the request
      ApiService.patch("/api/organizations/" + postForm.id, postForm)
        .then(({ data }) => {
          this.organizationForm = data;
          ApiService.get("/api/participants/bySelectedParticipant").then(({ data }) => {
            this.$auth.storeSelectParticipant(data).then(() => {
              window.scrollTo(0, 0);
              this.profileUpdated = true;
              setTimeout(() => {
                this.profileUpdated = false;
              }, 4000);
            });
          });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    validForm(newValue) {
      if (typeof newValue == "boolean") {
        this.$emit("input", this.validForm);
      }
    }
  },

  mounted() {
    this.fetchData();
    this.validForm = this.value;
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedParticipant", "impersonationParticipant"]),

    showName() {
      return (
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requireName != "INVISIBLE"
      );
    },
    showAddress() {
      return (
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requireAddress != "INVISIBLE"
      );
    },
    showPhone() {
      return (
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requirePhone != "INVISIBLE"
      );
    },
    showTIN() {
      return (
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requireTin != "INVISIBLE"
      );
    },

    requireName() {
      return (
        this.canEdit &&
        this.isModifiable &&
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requireName === "REQUIRED"
      );
    },
    requireAddress() {
      return (
        this.canEdit &&
        this.isModifiable &&
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requireAddress === "REQUIRED"
      );
    },
    requirePhone() {
      return (
        this.canEdit &&
        this.isModifiable &&
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requirePhone === "REQUIRED"
      );
    },
    requireTin() {
      return (
        this.canEdit &&
        this.isModifiable &&
        this.selectedParticipant.organization &&
        this.selectedParticipant.organization.organizationType.requireTin === "REQUIRED"
      );
    },

    canEdit() {
      return this.selectedParticipant.participantType.allowOrganizationManagement && this.isModifiable;
    }
  }
};
</script>
